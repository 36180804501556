"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Button, Container, Dropdown, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { usePathname, useRouter } from "next/navigation";
import { logout } from "@store/userLoginSlice";

import style from "../styles.module.scss";
import Footer from "../Footer";

export default function GuestLayout({ children }) {

  const accessToken = useSelector((state) => state.userToken.value.token);
  const user = useSelector((state) => state.userToken.value.user);
  const url = usePathname();
  const [logoUrl, setLogoUrl] = useState("/");
  const [showLogOutURL, setShowLogOutURL] = useState(false);
  const [name, setName] = useState("AB");

  const CustomToggle = React.forwardRef(({ children, onClick, href }, ref) => (
    <Link
      href=""
      ref={ref}
      className="text-decoration-none"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const dispatch = useDispatch();
  const router = useRouter();

  const handleLogout = () => {
    dispatch(logout());
    router.push("/login");
  };

  // updating logo url on the base of accessToken and user
  useEffect(() => {
    if (accessToken && user && user.role) {
      setLogoUrl("/assessment");
    }
  }, [accessToken, user]);

  // Show logout url based on screen
  useEffect(() => {
    if (accessToken && user && user.role) {
      setName(`${user.firstName[0]}${user.lastName[0]}`);
    }
  }, [accessToken, user]);

  return (
    <>
      <Navbar expand="lg" className="p-0">
        <Container>
          <Link
            href={logoUrl}
            className={`navbar-brand d-flex ${style.largeLogo}`}
          >
            <div className="me-1">
              <Image
                src={"/assets/logo/logo.png"}
                width={55}
                height={55}
                alt="Logo"
                className="d-block"
                priority
              />
            </div>
            <div>
              <h2>MIND BALANCE</h2>
              <p className="ps-1 mb-0">Brought to you by SmartGuy&reg;</p>
            </div>
          </Link>

          {url === "/email-verification" && (
            <Dropdown show={true} className={style.dropdownContainer}>
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
                className="mt-5"
              >
                <span
                  className="adminNav text-uppercase fs-5 rounded-circle p-2 text-white fw-semibold"
                  style={{ width: "40px", height: "40px" }}
                >
                  {name}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu
                className={`border-0 p-1 mt-1 shadow-sm ${style.dropdownMenu}`}
              >
                <Button
                  variant="link"
                  className={`dropdown-item text-dark ${style.dropdownItem}`}
                  onClick={() => handleLogout()}
                >
                  Logout
                </Button>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Container>
      </Navbar>
      <div className="bg-custom">
        {children}
      </div>
      <Footer />
    </>
  );
}
